#page-dreamcloud,
#page-dreamcloud *,
.page-dreamcloud--feedback,
.page-dreamcloud--feedback *,
.page-dreamcloud--posts,
.page-dreamcloud--posts * {
    box-sizing: border-box;
    --content-height: 72px;
    --content-spacer: 45px;
    --page-spacer: 300px;

    --color-default: #6E739E;
    --color-blue: #94bce4;
    --color-soft: #E8DCEA;
}
#page-dreamcloud .image img {
    width: 100%;
    max-height: calc(100vh - var(--mainmenu-height) - 100px);
    object-fit: contain;
    object-position: center;
}
#page-dreamcloud .image img.mobile {
  display: none;
}
#page-dreamcloud .image img.all {
  display: block;
}
#page-dreamcloud .intro-content {
  width: 85%;
  margin: 0 0 56px;
}
#page-dreamcloud p {
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
}
#page-dreamcloud .cover-header {
  margin: 0 0 40px;
}
#page-dreamcloud h2 {
  margin: 0 0 20px;
    font-size: 1.75rem;
    font-weight: 900;
    color: var(--color-default);
    text-transform: uppercase;
}
#page-dreamcloud .cover-list {
  margin: 0 0 59px;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 100px;
}
#page-dreamcloud .box {
    padding: 52px 55px;
    background: var(--color-white);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2); 
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
    align-self: center;
}
#page-dreamcloud .box {
    padding: 52px 55px;
    background: var(--color-white);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2); 
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
    align-self: center;
}
#page-dreamcloud .content {
  display: flex;
}
#page-dreamcloud .box {
    margin: 20px 0;
}
#page-dreamcloud .box h1 {
  max-width: 480px;
  margin: 0 0 20px;
  color: var(--color-default);
}
#page-dreamcloud .box p {
  margin: 0 0 24px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.2;
}
#page-dreamcloud .box .footnote {
  display: block;
  margin: 18px 0 0;
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  color: var(--color-default);
}
#page-dreamcloud .input--container {
  margin: 30px 0;
  padding: 30px;
  position: relative;
  border: 1px solid var(--color-soft);
}
#page-dreamcloud .form--lgpdalert {
  margin: 0 0 20px;
  font-size: 11px;
  font-weight: 300;
  font-style: italic;
  line-height: 18px;
}
#page-dreamcloud .input--container > label {
  display: block;
  position: absolute;
  top: -9px;
  left: 4px;
  margin: 0; 
  padding: 0 10px 0 6px;
  font-size: 12px;
  font-weight: 300;
  color: var(--color-default);
  background: var(--color-white);
}
#page-dreamcloud .input--container > textarea {
  font-size: 28px;
  font-weight: 700;
  color: var(--color-default);
}
#page-dreamcloud .input--container > textarea,
#page-dreamcloud .input--container > input {
    width: calc(100% - 20px);
    border: none;  
    padding: 0 10px;
    line-height: 1.2;
}
#page-dreamcloud ::placeholder,
#page-dreamcloud .form-group input {
  color: var(--color-default);
  font-size: 14px;
  font-weight: 300;
  width: calc(100% - 24px);
  left: 4px;
}
#page-dreamcloud .form-group input {
  border-bottom: 1px solid var(--color-soft);
}
#page-dreamcloud .plugins--container {
    width: calc(100% - 18px);
    position: absolute;
    bottom: -12px;
    left: 4px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#page-dreamcloud .plugins--container .char-counter {
    display: block;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 300;
    color: var(--color-default);
    background: var(--color-white);
}
#page-dreamcloud .plugins--container .toggle-container {
    display: block;
    padding: 0 10px;
    background: var(--color-white);
}
#page-dreamcloud .plugins--container .toggle-container .input--toggle > input {
    display: none;
}
#page-dreamcloud .plugins--container .toggle-container > label {
    margin: 0px 10px;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.5;
    color: var(--color-default);
}
#page-dreamcloud .plugins--container .toggle-container {
    display: flex;
}
#page-dreamcloud .plugins--container .toggle-container .input--toggle {
    margin: 0;
}
#page-dreamcloud .plugins--container .toggle-container .input--toggle:hover {
    cursor: pointer;
}
#page-dreamcloud .plugins--container .toggle-container .input--toggle > span,
#page-dreamcloud .plugins--container .toggle-container .input--toggle > span *,
#page-dreamcloud .plugins--container .toggle-container .input--toggle > span:before,
#page-dreamcloud .plugins--container .toggle-container .input--toggle > span:after  {
    transition: all 0.25s;
}
#page-dreamcloud .plugins--container .toggle-container .input--toggle > span {
    height: 16px;
    display: flex;
    position: relative;
    align-items: center;
}
#page-dreamcloud .plugins--container .toggle-container .input--toggle > span:before {
    width: 36px;
    height: 8px;
    border-radius: 4px;
    display: block;
    content: "";
    background: #d9dde4;
}
#page-dreamcloud .plugins--container .toggle-container .input--toggle > span:after {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: block;
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    background: #828fa5;
}
#page-dreamcloud .plugins--container .toggle-container .input--toggle > input:checked + span:before {
    background: #d5f4f7;
}
#page-dreamcloud .plugins--container .toggle-container .input--toggle > input:checked + span:after {
    left: auto;
    right: 0px;
    background: #74d9e3;
}
#page-dreamcloud .form--optin, #page-dreamcloud .form--optin a {
    font-size: 14px;
    font-weight: 700;
    color: var(--color-default);
  }
  #page-dreamcloud .form--optin label {
    margin: 0 0 0 6px;
  }
  #page-dreamcloud .form--optin a {
    text-decoration: underline;
  }
  #page-dreamcloud .form-submit {
      margin: 12px 0 0;
  }
  #page-dreamcloud .btn-sonhos,
  .page-dreamcloud--posts .btn-sonhos,
  .page-dreamcloud--feedback .btn-sonhos {
    width: 100%;
    max-width: 336px;
    padding: 15px 20px;
    border-radius: 0;
    font-size: 12px;
    font-weight: 400;
    color: var(--color-darkest);
    text-transform: uppercase;
    letter-spacing: 2px;
    background: var(--color-blue);
  }
  #Dreamcloud_index .tooltip * {
    font-size: 12px;
    font-weight: 300;
    color: #828FA5;  
  }
  #Dreamcloud_index .tooltip > div:nth-of-type(1) {
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #E6EAF2 transparent transparent transparent;
  }
  #Dreamcloud_index .tooltip > div:nth-of-type(1):after,
  #Dreamcloud_index .tooltip > div:nth-of-type(1):before {
    display: none;
  }
  #Dreamcloud_index .tooltip > div:nth-of-type(2) {
    padding: 10px;
    background: #E6EAF2;
    border-radius: 12px;
  }
  #page-dreamcloud .intro-content + .cta-expand {
    display: none;
  }
  @media (max-width: 992px) {
    #page-dreamcloud .image img.all {
        display: none;
    }
    #page-dreamcloud .image img.mobile {
        display: block;
    }
    #page-dreamcloud .intro-content {
      width: 100%;
      max-height: 85px;
      margin-bottom: 30px;
      position: relative;
      overflow: hidden;
      transition: all 0.75s ease-out;
    }
    #page-dreamcloud .intro-content:after {
      width: 100%;
      height: 100px;
      display: block;
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0px;
      background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
      z-index: 5;
    }
    #page-dreamcloud .intro-content + .cta-expand {
      display: inline;
      position: relative;
      top: -20px;
      font-size: 16px;
      font-weight: 300;
      color: var(--color-darkest);
      text-decoration: none;
    }
    #page-dreamcloud .intro-content.show + .cta-expand {
      display: none;
    }
    #page-dreamcloud .intro-content.show:after {
      opacity: 0;
    }
    #page-dreamcloud .intro-content.show {
      max-height: 100%;
    }
    #page-dreamcloud .box {
      width: 100%;
        -webkit-box-shadow: none;
        box-shadow: none;
        margin: 8px 0 100px;
        padding: 0;
    }
    #page-dreamcloud .btn-sonhos {
      max-width: 100%;
    }
    #page-dreamcloud .cover-list {
      display: none;
      grid-template-columns: 100%;
      gap: 16px;
    }
    #page-dreamcloud h2 {
      margin: 0 0 20px;
        font-size: 1.75rem;
        font-weight: 300;
        color: var(--color-default);
        text-transform: inherit;
    }
  }
  .page-dreamcloud--feedback .image {
    height: calc(100vh - var(--mainmenu-height) - 100px);
  }
  .page-dreamcloud--feedback .image > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
  .page-dreamcloud--feedback .box--container > .logo {
    position: absolute;
    top: 4.5vh;
  }
  .page-dreamcloud--feedback .box--container {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }
  .page-dreamcloud--feedback {    
    margin: 0 0 100px;
    position: relative; 
  }
  .page-dreamcloud--feedback .box {
    width: 100%;
    padding: 52px 55px;
    background: var(--color-white);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2); 
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
    z-index: 2;
  }
  .page-dreamcloud--feedback .box h1 {
    max-width: 272px;
    margin: 8px 0 18px;
    color: var(--color-default);
  }
  .page-dreamcloud--feedback .box p {
    font-weight: 300;
  }
  .page-dreamcloud--feedback .box p b {
    font-weight: 700;
  }


  @media (max-width: 992px) {
      p {
          font-size: 1rem;
      }
    .page-dreamcloud--feedback {
        height: auto;
        grid-template-columns: 100%;
        grid-template-rows: 60vh auto;
        grid-template-areas: "image" "alert"
    }
    .page-dreamcloud--feedback .content {
        justify-content: center;
    }
    .page-dreamcloud--feedback .image {
      height: auto;
    }    
    .page-dreamcloud--feedback .box--container {
     display: block;
      padding-bottom: 60px;
    }
    .page-dreamcloud--feedback .box--container > .logo {
      width: 100%;
      position: relative;
      top: 0;
      margin: 0 0 20px;
    }
    #page-dreamcloud {
      overflow-x: hidden;
    }
    #page-dreamcloud h1 {
      width: calc(100% + 28px);
      margin-right: -28px !important;
      height: 0;
      margin-right: 0px;
      display: block;
      position: relative;
      padding: 0 0 54%;
    }
    #page-dreamcloud h1 img {
      width: 100%;
      height: 100%;
      max-height: inherit !important;
      position: absolute;
      object-fit: cover !important;
      object-position: left center !important;
    }
  }

  .page-dreamcloud--posts .background-image {
    width: 50%;
    height: calc((100vh - var(--mainmenu-height) - 100px));
    position: fixed;
    top: var(--mainmenu-height);
    right: calc((100% - 1622px)/2);
    z-index: 1;
  }
  .page-dreamcloud--posts .background-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top center;
  }
  .page-dreamcloud--posts + footer {
    position: relative;
    z-index: 2;
    margin-top: 100px;
  }
  .page-dreamcloud--posts header,
  .page-dreamcloud--posts .content {
    position: relative;
    z-index: 2;
  }
  .page-dreamcloud--posts header {
    margin: 60px 0 16px;
  }
  .page-dreamcloud--posts .goto-post--container {
    margin: 0 0 60px;
    position: relative;
    z-index: 2;
  }
  .page-dreamcloud--posts .goto-post--container .btn-sonhos {
    color: var(--color-white);
    background: var(--color-default);
  }
  .page-dreamcloud--posts header h1 {
    margin: 0 0 15px;
    color: var(--color-default);
  }
  .page-dreamcloud--posts header p {
    margin: 0;
    font-weight: 300;
  }
  .page-dreamcloud--posts .message {
    margin-top: 60px;
    position: relative;
    z-index: 2;
    
  }

  .page-dreamcloud--posts .content {
    margin-bottom: 80px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 76px;
  }
  .page-dreamcloud--posts .share--container {
    margin-bottom: 60px;
  }
  .page-dreamcloud--posts .post--box {
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr auto;
    grid-template-areas: "post" "info";
    gap: 10px;
    position: relative;
    padding: 45px 50px ;
    background: var(--color-white);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2); 
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);  
  }
  .page-dreamcloud--posts .post--box > .cta-share--container {
    width: 38px;
    height: 38px;
    position: absolute;
    top: 0;
    right: 0;
  }
  .page-dreamcloud--posts .post--box > .cta-share--container a.cta-share {
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-lightest);
  }
  .page-dreamcloud--posts .post--box > .cta-share--container a.cta-share > .share {
    display: block;
  }
  .page-dreamcloud--posts .post--box > .cta-share--container a.cta-share > .close {
    display: none;
  }
  .page-dreamcloud--posts .post--box > .cta-share--container a.cta-share.show > .share {
    display: none;
  }
  .page-dreamcloud--posts .post--box > .cta-share--container a.cta-share.show > .close {
    display: block;
  }
  .page-dreamcloud--posts .post--box > .cta-share--container .dreamcloud-share--options {
    position: absolute;
    top: 0;
    left: -152px;
    display: none;
  }
  .page-dreamcloud--posts .post--box p {
    margin: 0;
    font-size: 28px !important;
    font-weight: 700 !important;
    line-height: 32px !important;
    color: var(--color-default);
    align-self: center;
  }
  .page-dreamcloud--posts .post--box p a {
    color: var(--color-default);
    text-decoration: none;
  }
  .page-dreamcloud--posts .post--box .info > * {
    display: block;
    font-size: 16px;
    font-weight: 300;
  }
  .page-dreamcloud--posts .post--box .info b {
    font-weight: 700;
  }
  .page-dreamcloud--posts .submiter {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2;
  }
  #page-dreamcloud--share header {
    margin: 46px 0 42px;
  }
  #page-dreamcloud--share header > a.btn-sonhos {
    width: 100%;
    max-width: 336px;
    display: inline-block;
    margin: 28px 0 0;
    color: var(--color-white);
    background: var(--color-default);
    text-align: center;
    text-decoration: none;
  }
  #page-dreamcloud--share .instructions p {
    margin: 0 0 16px;
  }
  #page-dreamcloud--share .instructions {
    margin: 34px 0;
  }
  #page-dreamcloud--share .dreamcloud-post--share {
    display: flex;
    flex-direction: column;
    gap: 36px;
  }
  #page-dreamcloud--share .dreamcloud-post--share .btn-sonhos {
    max-width: 100%;
    color: var(--color-white);
    background: var(--color-default);
  }
  #page-dreamcloud--share h2 {
    margin: 65px 0 0;
    font-size: 28px !important;
    font-weight: 700 !important;
    line-height: 32px !important;
    color: var(--color-default);
    text-transform: uppercase;
  }
  #page-dreamcloud--share .dreamcloud-share--options ul {
    gap: 20px;
  }
  #page-dreamcloud--share .dreamcloud-share--options ul li a {
    width: 100px;
  }
  .dreamcloud-post--share a.btn-sonhos img {
      margin: 0 10px 0 0;
    vertical-align: middle;
  }
@media (max-width: 1024px) {
  .page-dreamcloud--posts .post--box {
    padding: 45px 24px 24px;
  }
  .page-dreamcloud--posts .content {
    grid-template-columns: 100%;
    gap: 15px;
  }
  .page-dreamcloud--posts .background-image {
    width: 80%;
    right: -40%;
  }
}
#page-dreamcloud--share .row {
  position: relative;
  z-index: 2;
}
  /* Posts */
  .page-dreamcloud--posts .downloads {
    position: relative;
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap: 74px;
    z-index: 2;
  }
  .dreamcloud-post {
    display: inline-block;
    -webkit-box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%);
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%);
    background: var(--color-white);
    width: fit-content;
  }
  .dreamcloud-post .post--art {
      width: 1080px;
      height: 1080px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0 0 100px 10%;
      position: relative;
      overflow: hidden;
      background: var(--color-white);
      zoom: 28%;
  }
  .dreamcloud-post .post--art .post-image {
    height: 1080px;
    position: absolute;
    top: 0px;
    right: -1%;
  }
  .dreamcloud-post .post--art .post-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
  .dreamcloud-post .post--art .post-logo {
    width: 204px;
    height: 36px;
    position: absolute;
    top: 115px;
    left: 10%;
    z-index: 3;
  }
  .dreamcloud-post .post--art .post-content {
    max-width: 450px;
    position: relative;
    z-index: 4;
  }
  .dreamcloud-post .post--art .post-content p {
    font-size: 50px;
    font-weight: 700;
    line-height: 60px;
    color: var(--color-default);
    zoom: 99%;
  }
  .dreamcloud-post .post--art .post-content .author,
  .dreamcloud-post .post--art .post-content .author  span {
    display: flex;
    margin: 0;    
    font-size: 26px;
    color: var(--color-light);
    -webkit-text-size-adjust: none;
   
  }
  .dreamcloud-post .post--art .post-content .author
  {
     margin-top: 80px;
  }
  
  .dreamcloud-post .post--art .post-content .author.ios span {
    zoom: 99%;
  }
  .dreamcloud-post .post--art .post-content .complement {
    display: block;
    margin: 8px 0 0;
    font-size: 26px;
    color: var(--color-light);   
    -webkit-text-size-adjust: none;
  }
  .dreamcloud-post .post--art .post-content .complement.ios {    
    zoom: 99%;
  }
  .dreamcloud-post .post--art.stories {
    height: 1920px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr auto;
  }
  .dreamcloud-post .post--art.stories .post-image {
    height: auto;
    min-height: 0px;
    top: auto;
    right: auto;
    position: relative;
  }
  .dreamcloud-post .post--art.stories .post-image img {
    height: 100%;
    min-height: 0px;
  }
  .dreamcloud-post .post--art.stories .post-image img.pb-logo {
    width: 18.70%;
    height: auto;
    display: block;
    position: absolute;
    bottom: 100px;
    left: 0px;
  }
  .dreamcloud-post .post--art.stories .post-image img.futuro-logo {
    width: 40.64%;
    height: auto;
    display: block;
    position: absolute;
    bottom: 280px;
    left: 0px;
  }
  .dreamcloud-post .post--art.stories .post-content {
    max-width: 100%;
    padding-right: 100px; 
  }
  .dreamcloud-post .post--art.stories .post-content p {
    font-size: 90px;
    line-height: 105px;
    -webkit-text-size-adjust: none;
    zoom: 99%;
  }
  .dreamcloud-post .post--art .post-content .author,
  .dreamcloud-post .post--art .post-content .author span,
  .dreamcloud-post .post--art .post-content .complement {
    height: auto;
    line-height: 30px;
    -webkit-text-size-adjust: none;    
  }
  .dreamcloud-post .post--art .post-content .author
  {
    margin-top: 80px;
  }
  .dreamcloud-post .post--art .post-content .complement.ios {
    -webkit-text-size-adjust: none;
      zoom: 99%;
  }
  .dreamcloud-post .post--art .post-content .author.ios span
  {
    zoom: 99%;
  }
@media (max-width: 992px) {
  .page-dreamcloud--posts .downloads {
    grid-template-columns: 100%;
    gap: 30px;
  }
  #page-dreamcloud .input--container > textarea {  
    font-size: 20px;
  }
  #page-dreamcloud--share header > h1 > a > img
  {
    width: 100%;
  }
}
 
  .dreamcloud-share--options ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;  
  }
  .dreamcloud-share--options ul li a {
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-soft);
  }
  .dreamcloud-share--options ul li a > img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    object-position: center;
  }
  .dreamcloud-share--options ul li:nth-of-type(odd) a {
    background: var(--color-lightest);
  }
  
  #dreamcloud-goto-posts {
    width: 100%;
    height: 100px;
    padding: 0 20px;
    margin: 0 0 100px;
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    background: var(--color-default);
  }
  #dreamcloud-goto-posts img {
    width: 28px;
    height: 28px;
    object-fit: contain;
    object-position: center;
  }
  #dreamcloud-goto-posts span {
      font-size: 16px;
      font-weight:300;
      color: var(--color-soft);
  }
  #dreamcloud-goto-posts .btn-sonhos {
    color: var(--color-soft);
    background: var(--color-default);
    border: 2px solid var(--color-soft);
  }
  #dreamcloud-goto-posts > a > span.mobile-only {
    display: none;
  }
  @media (max-width: 992px) {
    #dreamcloud-goto-posts {
      height: auto;
      padding: 30px 20px;
      flex-wrap: wrap;
    }
    #dreamcloud-goto-posts > a > span.mobile-only {
      display: inline;
    }
    #dreamcloud-goto-posts > a > span {
      font-size: inherit;
      font-weight: inherit;
    }
    #dreamcloud-goto-posts > img,
    #dreamcloud-goto-posts > span {
      display: none;
    }
  }

  #page-dreamcloud--activated .box--container {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  #page-dreamcloud--activated .box--container header h1 {
    margin: 0 0 20px;
    color: var(--color-default);
  }
  #page-dreamcloud--activated .box--container header p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
  }
  #page-dreamcloud--activated .box--container header p > b {
    font-weight: 700;
  }
  #page-dreamcloud--activated .message {
    width: 100%;
    margin: 40px 0 26px;
  }
  #page-dreamcloud--activated .post--box {
    width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 1fr auto;
      grid-template-areas: "post" "info";
      gap: 10px;
      position: relative;
      padding: 45px 50px ;
      background: var(--color-white);
      -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2); 
      box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);  
    }
  #page-dreamcloud--activated .post--box p {
      margin: 0;
      font-size: 28px !important;
      font-weight: 700 !important;
      line-height: 32px !important;
      color: var(--color-default);
      align-self: center;
    }
  #page-dreamcloud--activated .post--box .info > * {
      display: block;
      font-size: 16px;
      font-weight: 300;
    }
  #page-dreamcloud--activated .post--box .info b {
      font-weight: 700;
    }
  #page-dreamcloud--activated .cta--container {
    width: 100%;
    display: flex;
    gap: 30px;
  }
  #page-dreamcloud--activated .cta--container > .btn-sonhos {
    color: var(--color-white);
    background: var(--color-default);
  }
  #page-dreamcloud--list .submiter .loading-gif {
    height: 30px;
  }
  @media (max-width: 992px) {
    #page-dreamcloud--activated .cta--container {
      flex-direction: column;
    }
  }


/* TV */

#Dreamcloud_tv {
  margin-top: 295px;
}
#page-dreamcloud--tv {
  position: relative;
}
#page-dreamcloud--tv .fixedheader {
  width: 100%;
  height: 275px;
  padding: 0 90px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 212px;
  border-bottom: 1px solid var(--color-lightest);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50000;
  background: url("/data/202202-futurosonhos/header-tv.png") right top no-repeat, #fff;
}
#page-dreamcloud--tv .fixedheader header {
  display: block;
}
#page-dreamcloud--tv .fixedheader .qr {
  display: flex;
  gap: 26px;
  align-items: center;
}
#page-dreamcloud--tv .fixedheader .qr span {
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
  color: var(--color-default);
  text-transform: uppercase;
}
#page-dreamcloud--tv .list {
  display: grid;
  column-gap: 46px;
  row-gap: 20px;
  padding: 0 56px 100px;
  position: relative;
  z-index: 1000;
}
#page-dreamcloud--tv .post--box {
  padding: 37px 72px 18px;
  min-height: 342px;
}
#page-dreamcloud--tv .post--box p {
  margin: 0 0 29px;
  font-size: 48px;
  line-height: 56px;
}
#page-dreamcloud--tv .post--container .post--box {
  padding: 37px 72px 18px;
}
#page-dreamcloud--tv .post--box .info {
  font-size: 16px;
}
#page-dreamcloud--tv .background-image {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
  overflow: hidden;
}
#page-dreamcloud--tv .background-image img {
  position: absolute;
  width: 60%;
  height: auto;
  top: 120px;
  left: -260px;
}

#page-dreamcloud form #message
{
  overflow: hidden;
  resize: none;
  min-height: 68px;
}



.dreamcloud-post .post--art.stories .post-image {
  width: 100%;
  height: 100%;
  background: url("/data/202202-futurosonhos/balloon_stories.png") top right no-repeat;
  background-size: contain
}
.dreamcloud-post .post--art.stories .post-image > img:nth-of-type(1) {
    opacity: 0;
}